import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';

function InfoCollect() {
  return (
    <Layout>
      <SEO title='Information Collected' />
      <div className='grid-container'>
        <div className='grid-row'>
          <div className='grid-col-12'>
            <h4 className='text-center'>
              Information Collected from This website for Statistical Purposes
            </h4>

            <p>
              xxx.yyy.com -- [28/Jan/2008:00:00:01 -0500] “GET /Defense/news/nr012708.html
              HTTP/1.0” 200 16704 Mozilla 3.0/www.google.com
            </p>
            <p>
              xxx.yyy.com (or 123.123.23.12)-- this is the host name (or Internet protocol
              (IP) address) associated with the requester (you as the visitor). In this
              case, the requester is coming from the xxx.yyy.net address. Depending on the
              requester's method of network connection, the host name (or IP address) may
              or may not identify the user’s specific computer. Connections via many
              Internet Service Providers (ISP) assign different IP addresses for each
              session, or only connect to the Internet via proxy servers, so the host name
              may only identify the ISP. The host name (or IP address) may identify a
              specific computer if that computer has a fixed IP address.
            </p>
            <p>
              [28/Jan/2008:00:00:01 -0500] -- this is the date and time of the request
            </p>
            <p>
              “GET /Defense/news/nr012708.html HTTP/1.0” -- this is the location of the
              requested file
            </p>
            <p>200 -- this is the status code - 200 is OK - the request was filled</p>
            <p>16704 -- this is the size of the requested file in bytes</p>
            <p>
              Mozilla 3.0 -- this identifies the type of browser software used to access
              the page, which indicates what design parameters to use in constructing the
              pages
            </p>
            <p>
              www.google.com -- this indicates the last site the person visited, which
              indicates how people find the requested file.
            </p>
            <p>
              Requests for other types of documents use similar information. Unless
              otherwise stated, no personally- identifiable information is collected.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default InfoCollect;
